import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import editIcon from "../../assets/editIcon.svg";
import loadingGif from "../../assets/loading2.gif";
import crossIcon from "../../assets/cross.svg";
import submitLogo from "../../assets/icon-submit.svg";
import downloadLogo from "../../assets/downloadIcon.svg";

import { saveAs } from "file-saver";

import "./select.css";
import axios from "axios";
import config from "../utils/config";
import Navbar from "../navbar/Navbar";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  let adminEmail = localStorage.getItem("superonAdmin");

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [userStatus, setUserStatus] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [allUser, setAllUser] = useState([]);
  const [allUserCopy, setAllUserCopy] = useState([]);
  const [downloadList, setDownloadList] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const handleSubmit = () => {
    setIsModalOpen(false);
    alert("Employee Status Updated");
  };

  // get all employee 🚀🚀🚀
  const getAllEmployee = async () => {
    // let data = {
    //   email: adminEmail,
    // };
    // console.log(data);
    try {
      setShowLoading(true);
      let response = await axios.post(config.ipAddress + `/employees`);

      console.log(response?.data?.data);
      const allUserData = response?.data?.data;
      allUserData.sort((a, b) => {
        const nameA = (a.name || "").toLowerCase();
        const nameB = (b.name || "").toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setAllUser(allUserData);
      setAllUserCopy(response?.data?.data);
      setDownloadList(response?.data?.data);
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
    }
  };

  // Update employee status
  const updateUserStatus = async (mobile, status) => {
    try {
      setShowLoading(true);

      let response = await axios.get(
        config.ipAddress + `/employee/${mobile}/${status}`
      );
      console.log(response);
      getAllEmployee();
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
    }
  };

  // search functionality
  const handleSearch = (value) => {
    if (!value) {
      getAllEmployee();
      return;
    }
    console.log(typeof searchValue);

    let filteredList = allUserCopy.filter((item, index) => {
      // console.log(e.target.value.toLowerCase(), item.dealername.toLowerCase());

      if (item.name) {
        if (value.toLocaleLowerCase() === "active") {
          return item.status.toLowerCase().trim().includes("2");
        }
        if (value.toLocaleLowerCase() === "inactive") {
          return item.status.toLowerCase().trim().includes("0");
        }
        if (value.toLocaleLowerCase() === "pending") {
          return item.status.toLowerCase().trim().includes("1");
        }

        return (
          item.name.toLowerCase().trim().includes(value.toLowerCase().trim()) ||
          item.email
            .toLowerCase()
            .trim()
            .includes(value.toLowerCase().trim()) ||
          item.mobile.toLowerCase().trim().includes(value.toLowerCase().trim())
          // item.status
          //   .toLowerCase()
          //   .trim()
          //   .includes(String(searchValue.toLowerCase()))
        );
      }

      // person.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      // person.mobile.includes(searchTerm) ||
      // person.email.toLowerCase().includes(searchTerm.toLowerCase())
    });

    console.log(filteredList);
    setAllUser(filteredList);
    setDownloadList(filteredList);
    if (searchValue.length < 0) {
      getAllEmployee();
    }
  };

  // Download Data 🚀🚀🚀
  const downloadData = () => {
    if (!downloadList.length) return;
    const xlsx = require("xlsx");

    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.json_to_sheet(downloadList.flat(10));
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelFileBuffer = xlsx.write(workbook, {
      type: "buffer",
      bookType: "xlsx",
    });

    const blob = new Blob([excelFileBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "data.xlsx";
    link.click();
  };

  // downlaod table data 🚀🚀🚀🚀🚀🚀
  function downloadTableData() {
    const xlsx = require("xlsx");

    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.table_to_sheet(
      document.getElementById(`tableId`)
    );
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `EmployeeTableData.xlsx`);
  }

  const convertIntoCamelCase = (name) => {
    // let nameArr = name.split(" ");
    // let firstName = nameArr[0];
    // let lastName = nameArr[1];
    if (!name) return;
    console.log(name);
    let formattedName = name.charAt(0) + name.slice(1).toLowerCase();

    return formattedName;
  };

  function formatName(name) {
    if (!name) return;
    const words = name.toLowerCase().split(" ");

    const formattedWords = words.map((word) => {
      if (word.length > 1) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word.toUpperCase();
      }
    });

    return formattedWords.join(" ");
  }

  const sortAllUser = () => {
    let val = allUser.sort((a, b) => {
      const nameA = (a.name || "").toLowerCase();
      const nameB = (b.name || "").toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setAllUser(val);
    console.log(val);
  };

  useEffect(() => {
    let isValidate = adminEmail;
    if (!isValidate) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getAllEmployee();
  }, []);

  return (
    <div className="w-full">
      <div className="">
        <div className="sticky top-0 left-0 z-[3]">
          <Navbar />
        </div>
        {/* <div className="w-[18%]">
          <Sidebar />
        </div> */}
        <div className="px-10  w-full min-h-[88vh] pt-4 bg-[#fafafa] overflow-auto ">
          <main className="bg-white w-full  shadow-6xl rounded-[10px] py-4 border-[1px] border-gray-300">
            {/* <h1 className="text-lg font-bold">Employee List</h1> */}

            <div className="flex items-center gap-2  px-2 bg-white w-full">
              <div className="bg-orange-light w-[2.5px] h-7 rounded-full"></div>
              <div className="flex justify-between items-center w-full">
                <h1 className="text-lg font-semibold">Employee List</h1>
                {/* Search  */}

                <div className="flex gap-4 mr-3">
                  <input
                    type="text"
                    placeholder="Search here"
                    className="  border text-[14px] border-gray-300 py-2 px-4 rounded-full  focus:outline-none placeholder:text-[16px] focus:border-orange-light shadow-lg"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />

                  {/* <div className="    h-[25px]  rounded-[4px] flex items-center justify-center">
                <img
                  src={crossIcon}
                  alt="submit-logo"
                  className="w-4 cursor-pointer"
                  onClick={() => {
                    getAllEmployee();
                    setSearchValue("");
                  }}
                />
              </div>
              <div className=" flex justify-center items-center   h-[25px]  rounded-[4px]">
                <img
                  src={submitLogo}
                  alt="submit-logo"
                  className="w-[18px]  cursor-pointer"
                  type="submit"
                  onClick={() => handleSearch()}
                />
              </div> */}
                  <img
                    src={downloadLogo}
                    title="Download"
                    alt="download-logo"
                    className="w-5 cursor-pointer"
                    onClick={() => downloadTableData()}
                  />
                </div>
              </div>
            </div>

            {/* User Table */}
            <section className="w-full pt-4 bg-white rounded-b-lg ">
              <div className="flex flex-col">
                <div className="overflow-x-auto ">
                  <div className="inline-block w-full ">
                    <div className="overflow-hidden">
                      <table
                        id="tableId"
                        className="w-full  text-center text-xs font-light "
                      >
                        <thead className="border-b font-medium  bg-gray-500 text-white">
                          <tr>
                            <th scope="col" className="border-r  px-6 py-3  ">
                              S. No.
                            </th>
                            <th scope="col" className="border-r  px-6 py-3  ">
                              Emp. Code
                            </th>
                            <th
                              onDoubleClick={() => sortAllUser()}
                              scope="col"
                              className="border-r  px-6 py-3  "
                            >
                              Name
                            </th>
                            {/* <th scope="col" className="border-r px-6 py-4  ">
                              Employee ID
                            </th> */}
                            <th scope="col" className="border-r px-6 py-3 ">
                              Email ID
                            </th>
                            {/* <th scope="col" className="px-6 py-4">
                              Join Date & Time
                            </th> */}

                            <th scope="col" className="border px-6 py-3 ">
                              Mobile
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Status
                            </th>
                            {/* <th scope="col" className="border-l px-6 py-4 ">
                              Action
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {allUser.length ? (
                            allUser.map((user, index) => {
                              return (
                                <tr
                                  key={index}
                                  className={`border-b text-sm ${
                                    index % 2 === 0
                                      ? "bg-white"
                                      : "bg-[#F9F9F9]"
                                  }`}
                                >
                                  <td className="whitespace-nowrap border-r px-6 py-1 font-medium ">
                                    {index + 1}
                                  </td>
                                  <td className="whitespace-nowrap border-r px-6 py-1 font-medium ">
                                    {user.officialemail}
                                  </td>
                                  <td className="whitespace-nowrap border-r px-6 py-1 font-medium ">
                                    {/* {convertIntoCamelCase(user.name)} */}
                                    {formatName(user.name)}
                                    {/* {user.name} */}
                                  </td>
                                  {/* <td className="whitespace-nowrap border-r px-6 py-4 ">
                                  11376
                                </td> */}
                                  <td className="whitespace-nowrap border-r px-6 py-1   ">
                                    {user.email}
                                  </td>

                                  {/* <td className="whitespace-nowrap border-r px-6 py-4 ">
                                  20 March 2023
                                </td> */}

                                  <td className="whitespace-nowrap border-r px-6 py-1 ">
                                    {user.mobile}
                                  </td>
                                  <td className="whitespace-nowrap  px-6 py-1  ">
                                    <select
                                      id=""
                                      className={` ${
                                        user.status === "0" &&
                                        "text-red-500 bg-red-100"
                                      } ${
                                        user.status === "1" &&
                                        "text-gray-500 bg-gray-100"
                                      }  ${
                                        user.status === "2" &&
                                        "text-green-500 bg-green-100"
                                      }  px-3  py-1 rounded-md focus:outline-none hover:cursor-pointer text-center`}
                                      onChange={(e) => {
                                        setIsDisabled(false);
                                        setUserStatus(e.target.value);
                                        updateUserStatus(
                                          user.mobile,
                                          e.target.value
                                        );
                                      }}
                                      value={user.status}
                                      // disabled={isDisabled}
                                    >
                                      <option
                                        value="0"
                                        className="bg-black text-white  inline-block pr-7"
                                      >
                                        Inactive
                                      </option>
                                      <option
                                        value="1"
                                        className="bg-black text-white  inline-block pr-7"
                                      >
                                        Pending
                                      </option>
                                      <option
                                        value="2"
                                        className="bg-black text-white  inline-block pr-7"
                                      >
                                        Active
                                      </option>
                                    </select>
                                  </td>

                                  {/* <td
                                  onClick={() => setIsModalOpen(true)}
                                  className="whitespace-nowrap  px-6 py-4  "
                                >
                                  <span className="inline-block w-20 bg-blue-400  py-2 text-white rounded-md cursor-pointer">
                                    View
                                  </span>
                                </td> */}
                                </tr>
                              );
                            })
                          ) : (
                            <h1 className="text-lg">No data</h1>
                          )}

                          {/* <tr className="border-b text-lg">
                            <td className="whitespace-nowrap border-r px-6 py-1 font-medium ">
                              Koushik Saha
                            </td>
                           
                            <td className="whitespace-nowrap border-r px-6 py-1   cursor-pointer">
                              fe@stantech.ai
                            </td>


                            <td className="whitespace-nowrap border-r px-6 py-1 ">
                              9988776655
                            </td>

                            <td className="whitespace-nowrap  px-6 py-1  font-semibold">
                              <span className="inline-block bg-red-100 text-red-500 px-3 py-1 rounded-md w-20">
                                Inactive
                              </span>
                            </td>

                         
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>

      {/* user modal */}
      {isModalOpen && (
        <section className="absolute  z-20   top-[10%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-200px] shadow-md border-[1px] border-gray-400  rounded-[10px]">
          <div className="w-[400px] h-[440px] bg-white border px-4 py-5 rounded-[10px]">
            <h1 className="text-center font-semibold">Employee Details</h1>
            <hr className="h-[1px] bg-gray-400" />

            <div className="mt-7 ">
              <h1>
                <span>Name : </span>
                <span>Koushik Saha</span>
              </h1>
            </div>

            <div className="mt-4 ">
              <h1>
                <span>Email : </span>
                <span>fe@stantech.ai</span>
              </h1>
            </div>

            <div className="mt-4 ">
              <h1>
                <span>Status : </span>
                <span>Active</span>
              </h1>
            </div>
            <hr className="h-[1px]  mt-3 bg-gray-400" />

            <div className="mt-7">
              <h1 className="text-center font-semibold text-orange-light">
                Modify Employee Status
              </h1>

              <div className="mt-3">
                <label htmlFor="" className="font-medium text-md">
                  Select Employee Status
                </label>
                <br />
                <select
                  name=""
                  id=""
                  className="flex w-full rounded-md border-solid border-[1px] box-border  border-gray-300 mt-1 py-3 px-2 text-sm font-medium overflow-auto   focus:outline-none focus:border-[#FF971A]"
                >
                  <option value="Select Option">Select Option</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>

                <div className="text-center mt-4">
                  <button
                    onClick={() => handleSubmit()}
                    className=" rounded-md w-full py-2 bg-[#FF971A] text-center text-lg font-semibold  text-white "
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {isModalOpen && (
        <div
          onClick={() => setIsModalOpen(false)}
          className="absolute h-screen w-full z-[1] opacity-60 bg-gray-300 top-0 left-0"
        ></div>
      )}

      {showLoading && (
        <div
          className="absolute z-20  text-center top-[35%] left-[50%] mt-[50px] mr-0 mb-0 ml-[-50px]"
          // onBlur={() => setIsModalOpen(false)}
        >
          <img src={loadingGif} className="w-[100px]" alt="loading-logo" />
        </div>
      )}

      {showLoading && (
        <div
          // onClick={() => setIsModalOpen(false)}
          className="w-full h-[100dvh] bg-[#A8A8A8] absolute top-0 opacity-30 z-10"
        ></div>
      )}
    </div>
  );
}

export default Dashboard;
